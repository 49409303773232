<template>
    <div>
        <mercur-dialog :is-open.sync="isStoppingArtworkCheck">
            <h3 class="font-weight-normal mt-1">Please add log information</h3>
            <form @submit.prevent="stopOrderCheck">
                <mercur-textarea v-model="form.reason" placeholder="I checked in this order because:" :class="getValidationClass($v, 'reason')">
                    I stopped this artwork check because:
                    <template #note>
                        <span class="form-error" v-if="!$v.form.reason.required">Reason is required</span>
                    </template>
                </mercur-textarea>
                <div class="text-right">
                    <mercur-button class="btn" @click="isStoppingArtworkCheck = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" type="submit" :disabled="loading">CHECK OUT</mercur-button>
                </div>
            </form>
        </mercur-dialog>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/Form'

export default {
    name: 'StopCheckDialog',
    mixins: [FormMixin],
    data () {
        return {
            form: {},
            isStoppingArtworkCheck: false,
        }
    },
    methods: {
        stopOrderCheck () {
            if (this.$v) {
                this.$v.$touch()
            }

            if (this.$v && this.$v.$invalid) {
                return
            }
            this.$emit('submitted', { form: this.form })
            this.isStoppingArtworkCheck = false
        },
    },
    validations: {
        form: {
            reason: {
                required,
            },
        },
    },
}
</script>
