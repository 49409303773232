<template>
    <div class="c-orderline-view pb-5">
        <v-title v-if="Object.keys(order).length" :title="`${order.orderNumber} - ${orderLine.orderLineNumber} - Orders`"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex">
                        <h1 class="flex-grow-1 mb-3">
                            Orderline {{ orderLine.orderLineNumber }}
                            <span class="c-order-view__title-span">
                                <status-chip v-if="order.quote" color="purple">From quote</status-chip>
                                <status-chip v-if="orderLine.orderLineStatus">{{ orderLine.orderLineStatus }}</status-chip>
                                <status-chip v-if="artworkStatus">Artwork status: {{ artworkStatus }}</status-chip>
                                <router-link  v-if="orderLine.reprintedFromOrderLineId" :to="getOrderLineRoute(orderLine.reprintedFromOrderLineId)">
                                    <status-chip class="ml-3">Reprint of {{ getOrderLineNumber(orderLine.reprintedFromOrderLineId)}} </status-chip>
                                </router-link>
                            </span>
                        </h1>
                        <div class="text-right" v-if="Object.keys(orderLine).length && isDataSet">
                            <mercur-menu class="mr-3 d-inline-block">
                                <mercur-button data-e2e="orderlineAction" class="btn">Actions</mercur-button>
                                <div slot="dropdown">
                                    <mercur-item data-e2e="orderlineActionCancelOrderlineOption" @click.native="triggerCancellationDialog" :disabled="immutableStatuses.includes(orderLine.orderLineStatus) || isLoading">Cancel Orderline</mercur-item>
                                    <mercur-item data-e2e="orderlineActionArtworkNoProofRequestedOption" :disabled="$route.name !== 'OrderLineArtworkCheck' || orderLine.noProofRequested" @click.native="setNoProofRequested">No proof requested</mercur-item>
                                    <mercur-item data-e2e="orderlineActionManualCheckArtworkOption" @click.native="setArtworkToManualCheck">Manual check needed</mercur-item>
                                    <mercur-item data-e2e="orderlineActionArtworkInfoNeededOption" @click.native="setArtworkToRequestInfo">Artwork Info needed</mercur-item>
                                </div>
                            </mercur-menu>
                            <span v-if="canGoToProduction" class="mr-3">
                                <mercur-tooltip>
                                    <template #label>This order requires two artwork checks</template>
                                    <mercur-button class="btn btn-yellow" @click.native="setReadyForProduction" :disabled="isLoading || noFirstCheck">
                                        {{ artworkStatus === 'APPROVED' ? 'Orderline ready for production' : 'Approve artwork and go to production' }}
                                    </mercur-button>
                                </mercur-tooltip>
                            </span>
                            <template v-if="!immutableStatuses.includes(orderLine.orderLineStatus)">
                                <mercur-button class="btn btn-primary text-uppercase" v-if="!isAssigned" @click.native="assignOrder" :disabled="!canAssignOrderLineArtwork"><i class="fas fa-step-forward"></i>Check in</mercur-button>
                                <mercur-button class="btn btn-primary text-uppercase" v-if="isAssigned && isUserOrder" @click.native="initiateStopOrderCheck" :disabled="isLoading || orderLine.orderLineStatus === 'CANCELLED'"><i class="fas fa-stop"></i>Check out</mercur-button>
                                <mercur-button class="btn btn-primary text-uppercase" v-if="isAssigned && allChecksDone" @click.native="assignFirstOrder" :disabled="isLoading"><i class="fas fa-step-forward"></i>Next check</mercur-button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </title-box>
        <div class="ml-n3 mr-n3 mt-n4" v-if="Object.keys(orderLine).length">
            <div class="orderline-tabs px-3">
                <div class="container-fluid">
                    <mercur-tabs class="orderline-tabs__overflow">
                        <mercur-tab exact v-for="(orderLine, index) in order.orderLines" :key="index" :title="orderLine.orderLineNumber" :to="getOrderLineRoute(orderLine.orderLineId)"></mercur-tab>
                    </mercur-tabs>
                </div>
            </div>
            <div class="orderline-detail-tabs px-3">
                <div class="container-fluid">
                    <mercur-tabs data-e2e="orderlineViewArtworkTab">
                        <mercur-tab title="Information" :to="getOrderLineDetailTab('OrderLineView')" exact></mercur-tab>
                        <mercur-tab title="Artwork Check" :to="getOrderLineDetailTab('OrderLineArtworkCheck')"></mercur-tab>
                        <mercur-tab title="History" :to="getOrderLineDetailTab('OrderLineHistory')"></mercur-tab>
                        <mercur-tab title="Complaints" :to="getOrderLineDetailTab('OrderLineComplaints')"></mercur-tab>
                    </mercur-tabs>
                </div>
            </div>
        </div>
        <div v-if="Object.keys(orderLine).length">
            <div class="container-fluid mt-5">
                <transition name="fade" mode="out-in" appear>
                    <router-view ref="routeview" :isOverWarningValue="isOverWarningValue" v-bind="$data"></router-view>
                </transition>
            </div>
            <stop-check-dialog ref="stopCheckDialog" @submitted="submitted"></stop-check-dialog>
            <mercur-dialog data-e2e="orderlineCancelOrderlineModal" :is-open.sync="showCancelDialog">
                <h3 class="font-weight-normal mt-1">Cancel orderline</h3>
                <p>Please tell us the reasons for cancelling your orderline</p>
                <mercur-select data-e2e="orderlineCancelModalReasonSelect" v-if="reasons" v-model="cancellationReason">
                    <template #label>Reasons</template>
                    <option v-for="(reason, index) in reasons" :key="index" :value="reason">{{ reason }}</option>
                </mercur-select>
                <template #footer>
                    <mercur-button class="btn" @click.native="showCancelDialog = false" >Cancel</mercur-button>
                    <mercur-button data-e2e="orderlineCancelModalCancelButton" class="btn btn-primary" @click.native="cancel" :disabled="isLoading">Cancel orderline</mercur-button>
                </template>
            </mercur-dialog>
        </div>
    </div>
</template>

<script>
import StatusChip from '@/components/utils/StatusChip'
import { mapActions, mapState, mapGetters } from 'vuex'
import StopCheckDialog from '@/components/elements/artwork/StopCheckDialog'
import moment from 'moment'
import CONFIG from '@root/config'

export default {
    name: 'OrderLineView',
    components: { StatusChip, StopCheckDialog },
    data () {
        return {
            isLoading: false,
            isDataSet: false,
            immutableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
            showCancelDialog: false,
            cancellationReason: '',
            isTabOverflow: false,
            warningValue: 400,
        }
    },
    computed: {
        ...mapState('order', ['order', 'orderLine', 'assignedOrder', 'reasons']),
        ...mapGetters('order', ['artworkStatus', 'activeArtwork', 'orderLineHasCpdf']),
        ...mapState('auth', ['userAdditionalData']),
        breadCrumbId () {
            if (!this.order) {
                return '...'
            }
            return this.orderLine.orderLineNumber
        },
        canAssignOrderLineArtwork () {
            if (this.isLoading) {
                return false
            }
            if (this.orderLine.orderLineStatus === 'CANCELLED') {
                return false
            }

            if (this.order.quote === false) {
                return true
            }

            if (!this.userAdditionalData || !this.userAdditionalData.accountSettings || !this.userAdditionalData.accountSettings.artworkGrades || !this.userAdditionalData.accountSettings.artworkGrades.includes('SPECIALIST')) {
                return false
            }

            return true
        },
        isAssigned () {
            if (this.$store.state.order.assignedOrder) {
                return true
            }
            return false
        },
        isUserOrder () {
            if (this.$store.state.order.assignedOrder.orderId === this.order.orderId) {
                return true
            }
            return false
        },
        allChecksDone () {
            if (!this.isDataSet) {
                return false
            }

            let isFinished = true

            this.order.orderLines.forEach((orderLine) => {
                if (!orderLine.artworkTasks) {
                    isFinished = false
                    return
                }

                if (!orderLine.artworkTasks.COMPLETED || !orderLine.artworkTasks.CHECK) {
                    isFinished = false
                    return
                }

                if (!orderLine.artworkTasks.COMPLETED[this.$store.state.auth.user.accountId]) {
                    isFinished = false
                    return
                }

                const check = orderLine.artworkTasks.CHECK[this.$store.state.auth.user.accountId]
                const completed = orderLine.artworkTasks.COMPLETED[this.$store.state.auth.user.accountId]

                const lastCheck = check[check.length - 1]
                const lastCompleted = completed[completed.length - 1]
                if (moment(lastCheck.dateTo).utc().unix() !== moment(lastCompleted.dateTo).utc().unix()) {
                    isFinished = false
                }
            })

            return isFinished
        },
        getTabClass: function () {
            return (orderLineNumber) => {
                if (!orderLineNumber) {
                    return
                }

                if (!this.$store.getters || !this.$store.getters['order/orderLineQueueStatusByNumber']) {
                    return
                }

                const orderLineArtworkStatus = this.$store.getters['order/orderLineQueueStatusByNumber'](orderLineNumber)

                if (!orderLineArtworkStatus) {
                    return
                }

                const className = 'orderline-tabs__tab'
                const color = orderLineArtworkStatus.supplierSla
                const SLA_TIME = 0.45 // 45 minutes
                const value = parseFloat(color.replace(':', '.'))
                if (value < 0) {
                    return `${className}--red`
                } else if (value < SLA_TIME) {
                    return `${className}--yellow`
                } else {
                    return `${className}--green`
                }
            }
        },
        canGoToProduction () {
            if (!this.order) {
                return false
            }

            if (this.immutableStatuses.includes(this.orderLine.orderLineStatus)) {
                return false
            }

            if (!this.orderLineHasCpdf) {
                return false
            }

            if (typeof this.order.payments === 'object' && Object.keys(this.order.payments).length) {
                return true
            }

            return false
        },
        noFirstCheck () {
            if (!this.isOverWarningValue) {
                return false
            }
            return this.artworkStatus !== 'ATTENTION'
        },
        isOverWarningValue () {
            if (!this.orderLine.product.prices.currencySpecificPrices || Array.isArray(this.orderLine.product.prices.currencySpecificPrices)) {
                return
            }
            return this.orderLine.product.prices.currencySpecificPrices.original.salesPrice > this.warningValue
        },
    },
    watch: {
        '$route.params.orderLineId' () {
            this.setData()
        },
    },
    methods: {
        ...mapActions('order', [
            'fetchOrder', 'setOrder', 'setOrderLine', 'getTurnaroundCalculation',
            'assignOrderToAgent', 'unassignOrderFromAgent', 'skipOrder',
            'assignFirstOrderToAgent', 'checkAssignedOrder', 'fetchOrderArtworkStatus',
            'fetchSupplierData', 'cancelOrderLine', 'fetchCancellationReasons', 'updateOrderOrderline', 'setOrderLineStatus',
        ]),
        ...mapActions('customer', ['fetchCustomer']),
        ...mapActions('shop', ['checkShop']),
        ...mapActions('auth', ['getAccountAdditionalSettings']),
        getOrderLineDetailTab (name) {
            return this.$router.resolve(
                {
                    name: name,
                    params: {
                        ...this.$route.params,
                    },
                }
            ).href
        },
        getTabTruncated (item) {
            const itemLength = item.label.length
            let label = item.label
            if (this.order.orderLines.length >= 14) {
                label = item.label.substring(8, itemLength)
                this.isTabOverflow = true
            }
            return label
        },
        getOrderLineRoute (id) {
            return this.$router.resolve({
                name: this.$route.name,
                params: {
                    ...this.$route.params,
                    orderLineId: id,
                },
            }).href
        },
        getOrderLineNumber (id) {
            const orderLine = this.order.orderLines.find(e => e.orderLineId === id)
            if (!orderLine) {
                return 'unknown'
            }
            return orderLine.orderLineNumber
        },
        setData () {
            this.checkAssignedOrder()
            this.setOrderLine(this.$route.params.orderLineId)
            this.fetchOrder({
                orderId: this.$route.params.orderId,
                currentOrderline: this.$route.params.orderLineId,
            }).then(() => {
                if (!this.orderLine.product.turnaroundHash && !this.orderLine.product.producer.sourceId) {
                    this.$root.$emit('notification:global', {
                        message: 'No supplier data found',
                    })
                } else {
                    this.fetchSupplierData()
                }
                this.getTurnaroundCalculation()
                this.isDataSet = true
            })
            this.fetchCustomer({
                accountId: this.$route.params.accountId,
            })

            this.fetchOrderArtworkStatus({
                orderId: this.$route.params.orderId,
            })
        },
        assignOrder () {
            this.isLoading = true
            this.assignOrderToAgent({
                orderId: this.order.orderId,
                accountId: this.$store.state.auth.user.accountId,
                name: `${this.$store.state.auth.user.firstName} ${this.$store.state.auth.user.lastName}`,
                customerId: this.$route.params.accountId,
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isLoading = false
            })
        },
        initiateStopOrderCheck () {
            let isCheckFinished = true
            this.order.orderLines.forEach((orderLine) => {
                if (!orderLine.artworkTasks) {
                    isCheckFinished = false
                } else if (!Object.keys(orderLine.artworkTasks).includes('COMPLETED')) {
                    isCheckFinished = false
                }
            })

            if (isCheckFinished) {
                this.stopOrderCheck()
            } else {
                this.$refs.stopCheckDialog.isStoppingArtworkCheck = true
            }
        },
        stopOrderCheck (data = null) {
            this.isLoading = true
            this.unassignOrderFromAgent({
                orderId: this.order.orderId,
                accountId: this.$store.state.auth.user.accountId,
                customerId: this.$route.params.accountId,
                reason: data.form ? data.form.reason : '',
            }).then(() => {
                this.isLoading = false
                this.$router.push({
                    name: 'ArtworkCheck',
                })
            })
        },
        assignFirstOrder () {
            this.$cookies.remove(CONFIG.COOKIES.ASSIGNED_ORDER.KEY, '/')
            this.isLoading = true
            this.assignFirstOrderToAgent({
                accountId: this.$store.state.auth.user.accountId,
            }).then(() => {
                this.$router.push({
                    name: 'OrderLineArtworkCheck',
                    params: {
                        orderId: this.assignedOrder.orderId,
                        orderLineId: this.assignedOrder.orderLineIds[0],
                    },
                })
                this.isLoading = false
            })
        },
        submitted (data) {
            this.stopOrderCheck(data)
        },
        cancel () {
            this.isLoading = true
            this.cancelOrderLine({
                url: CONFIG.API.ROUTES.ORDERS.ORDERLINE.CANCEL.replace('{accountId}', this.$route.params.accountId)
                    .replace('{orderId}', this.order.orderId)
                    .replace('{orderLineId}', this.orderLine.orderLineId),
                reason: this.cancellationReason,
            }).then(() => {
                this.isLoading = false
                this.$segmentClient.sendAction(this, 'Cancel Order Orderline', { userId: this.$route.params.accountId })
                this.$root.$emit('notification:global', {
                    message: 'Order Line was cancelled',
                })
                this.showCancelDialog = false
            })
        },
        triggerCancellationDialog () {
            this.showCancelDialog = true
            this.isLoading = true
            this.fetchCancellationReasons({
                orderId: this.order.orderId,
            }).then(() => {
                this.isLoading = false
            })
        },
        setNoProofRequested () {
            const url = CONFIG.API.ROUTES.ORDERS.ORDERLINE.NO_PROOF.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.$route.params.orderId).replace('{orderLineId}', this.$route.params.orderLineId)
            this.post(url, {}, 'No proof requested was set').then((data) => {
                this.updateOrderOrderline({
                    id: data.data.orderLineId,
                    data: { noProofRequested: data.data.noProofRequested },
                })
            })
        },
        listen () {
            this.$router.push({
                name: 'OrdersWrapper',
            })
        },
        setArtworkToManualCheck () {
            const url = CONFIG.API.ROUTES.ARTWORKS.SET_TO_MANUAL_CHECK.replace('{accountId}', this.$route.params.accountId)
            const payload = {
                orderId: this.$route.params.orderId,
                artwork: {
                    artworkId: this.activeArtwork.artworkId,
                    orderLineId: this.activeArtwork.orderLineId,
                },
            }
            this.post(url, payload, 'Artwork status was set to manual check').then(({ data }) => {
                this.setOrder({
                    data: data,
                    currentOrderline: this.$route.params.orderLineId,
                })
            })
        },
        setArtworkToRequestInfo () {
            const url = CONFIG.API.ROUTES.ARTWORKS.SET_TO_REQUEST_INFO.replace('{accountId}', this.$route.params.accountId)
            const payload = {
                orderId: this.$route.params.orderId,
                artwork: {
                    artworkId: this.activeArtwork.artworkId,
                    orderLineId: this.activeArtwork.orderLineId,
                },
            }
            this.post(url, payload, 'Artwork status was set to request info').then(({ data }) => {
                this.setOrder({
                    data: data,
                    currentOrderline: this.$route.params.orderLineId,
                })
            })
        },
        setReadyForProduction () {
            const url = CONFIG.API.ROUTES.ORDERS.FOLLOWUP.SUBMIT
                .replace('{accountId}', this.$store.state.auth.user.accountId)
                .replace('{customerId}', this.$route.params.accountId)
                .replace('{orderId}', this.order.orderId)
                .replace('{orderLineId}', this.orderLine.orderLineId)

            const payload = {
                artworkFollowupAction: 'SEND TO PRODUCTION',
                message: 'Approve artwork and set ready for production',
                title: 'Send to production',
            }

            payload.amount = parseFloat(this.orderLine.orderLineTotals.productsPrice) + parseFloat(this.orderLine.orderLineTotals.shippingPrice)
            this.isLoading = true
            this.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Artwork was approved and orderline was set ready for production',
                })
                this.fetchOrder({
                    orderId: this.$route.params.orderId,
                    currentOrderline: this.$route.params.orderLineId,
                })
                this.fetchOrderArtworkStatus({
                    orderId: this.order.orderId,
                })
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isLoading = false
            })
        },
    },
    created () {
        this.checkShop(this.$route.params.applicationId)
        this.setData()
        this.getAccountAdditionalSettings()
    },
    mounted () {
        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}
</script>

<style lang="scss">
    $dark-grey: #666666;
    $light-grey: #CBCBCB;
    .c-orderline-view {

        .orderline-tabs {
            background: $light-grey;
            position: relative;

            &__tab {
                height: 48px;
                display: flex;
                align-items: center;
                padding: 0 16px;
                justify-content: center;
                font-weight: bold;

                &--red {
                    background-color: #DA0724;
                    color: white;
                }

                &--green {
                    background-color: #77BE29;
                    color: white;
                }

                &-yellow {
                    background-color: #F1B818;
                }
            }
            &__overflow {
                overflow: auto;
                white-space: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .orderline-detail-tabs {
            background: transparent;
            margin-bottom: -30px;
        }
    }

    .artwork-dialog {
        width: 600px;

        &__btn-submit {
            margin-left: 10px !important;
        }
    }

</style>
